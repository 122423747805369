*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.svgItem {
  overflow: visible;
  stroke: #fff;
  shape-rendering: geometricPrecision;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  height: 18px;
}
.darkCalendar {
  background-color: rgba(49, 47, 47, 1) !important;
  z-index: 1000;
}
.darkCalendar .react-calendar__navigation button {
  color: white;
}

.lightCalendar {
  background-color: rgba(253, 255, 252, 0.6) !important;
}

.react-daterange-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__divider,
.react-daterange-picker__range-divider {
  color: black !important;
}
.react-daterange-picker__calendar {
  z-index: 1000 !important;
}

.carousel__dot-group > button {
  width: 16px;
  height: 16px;
  font-size: 8px;
  border-radius: 50%;
  margin: 2px;
  background: var(--ligth-primaryColor-white);
  color: black;
  border: 1px solid var(--dark-primaryColor-dark);
  transition: 0.3s all;
}
.carousel__dot-group > button:disabled {
  background: var(--dark-Color-dark);
  border: 1px solid black;
  color: var(--ligth-primaryColor-white);
}
.caruselDots > button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
  background: var(--ligth-primaryColor-white);
  border: 1px solid var(--dark-primaryColor-dark);
}
.caruselDots > button:disabled {
  background: var(--dark-primaryColor-dark);
}
.caruselDotsShops > button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 10px;
  background: var(--ligth-primaryColor-white);
  border: 1px solid var(--dark-primaryColor-dark);
}
.caruselTopDealsDots > button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
  background: var(--ligth-primaryColor-white);
  border: 1px solid var(--dark-primaryColor-dark);
}
.caruselDots > button:disabled,
.caruselDotsShops > button:disabled,
.caruselTopDealsDots > button:disabled {
  background: var(--dark-Color-dark);
  border: 1px solid black;
}

.tools {
  position: fixed;
  width: 100%;
  height: 0;
  bottom: 75px;
  right: 0;
  z-index: 89;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background: var(--ligth-primaryColor-white);
  background-image: linear-gradient(
    315deg,
    "#ccc3c3" 0%,
    var(--ligth-primaryColor-white) 74%
  );
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tools.dark {
  background: var(--dark-sixColor-dark);
  background-image: linear-gradient(
    315deg,
    "#0a0a0a " 0%,
    var(--dark-primaryColor-dark) 74%
  );
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tools button:nth-of-type(5) {
  position: fixed;
  bottom: 20px;
  right: 10px;
}
.tools button:nth-of-type(4) {
  position: fixed;
  bottom: 20px;
  right: 50px;
}
.tools button:nth-of-type(3) {
  position: fixed;
  bottom: 20px;
  right: 90px;
}
.tools button:nth-of-type(2) {
  position: fixed;
  bottom: 20px;
  right: 10px;
}
.tools div:nth-of-type(1) {
  position: fixed;
  bottom: 28px;
  right: 125px;
}
.tools div:nth-of-type(1) div {
  position: absolute;
}
.tools div:nth-of-type(2) {
  position: fixed;
  bottom: 0px;
  left: 10px;
}
.tools button:nth-of-type(1) {
  position: fixed;
  bottom: 20px;
  right: 50px;
}
/* className={theme?.isDark ? "citySearchDark" : "citySearchLigth"} */
.citySearchDark,
.citySearchLigth {
  width: 50%;
  z-index: 1000;
}
.paginationLight {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  margin: 40px 0;
  user-select: none;
}
.paginationLight > li {
  width: 30px;
  cursor: pointer;
  text-align: center;
  transform: scale(1);
  transition: 0.3s all;
}
.selected {
  color: rgba(46, 196, 182, 1);
  transform: scale(1.2) !important;
  font-weight: bold;
  padding: 0 6px;
}
.next {
  width: 55px !important;
}
.previous {
  width: 75px !important;
}
/* .leaflet-marker-icon {
  z-index: 998 !important;
} */
/* .citySearchDark {
  background-color: rgba(49, 47, 47, 1) !important;
  color: white;
}
.citySearchLigth {
  background-color: rgba(253, 255, 252, 0.6) !important;
  color: black;
} */

@media (max-width: 767.98px) {
  .tools {
    display: none;
  }
  .citySearchDark,
  .citySearchLigth {
    width: 95%;
  }
  .paginationLight {
    margin: 40px 0;
  }
}
